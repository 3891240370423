module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-9PKD69WS6X"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2feef32aedeef56b0a981f6b09826bba"},
    },{
      plugin: require('../node_modules/@shapeable/gatsby-theme-shapeable-web/gatsby-browser.js'),
      options: {"plugins":[],"redirects":{},"fonts":["Cormorant+Garamond:ital,wght@0,500;1,500","Jost:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600"],"entities":{"Post":{"filterFieldNames":["topics"]},"Event":{},"Video":{},"FeedEntry":{}},"siteMetadata":{"title":"","description":"","image":"","video":"","twitter":"","threads":"","linkedin":"","facebook":"","flickr":"","tiktok":"","youtube":"","instagram":"","name":"","logo":"","titleTemplate":"%s - %s","openGraphTitleTemplate":"%s - %s"}},
    }]
