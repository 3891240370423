import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { 
  LayoutShell, 
  SiteFooterLayout, WebProvider, 
  WebConfig,
} from '@shapeable/web';

import { EmbedComponentsProvider, useEmbedComponents } from '@shapeable/ui';
import { VERY_LIGHT_BROWN, DARK_GREEN, DARK_BLUE } from '../theme';
import * as pageLayouts from './page-layouts';
import * as sliceLayouts from './slice-layouts';
import { LANG_STRINGS } from '../lang';
import { PostsProvider } from '../gatsby/providers/posts-provider';
import { MenusProvider } from '../gatsby/providers/menus-provider';
import { LayoutStyles, themeOverrides } from '../theme';

export type LayoutPropTypes = Classable & HasChildren & {
  path?: string;
  data?: any;
};

const LayoutDefaultProps: LayoutPropTypes = {
};

// -------- Styles --------> 

const ContainerStyles = breakpoints({
  base: css`
    ${LayoutStyles}
  `,
});


const FooterStyles = breakpoints({
  base: css`
    flex-grow: 0;
    flex-shrink: 0;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,
});

// -------- Components -------->

const My = {
  Container: styled(LayoutShell)`${ContainerStyles}`,

  Body: styled.div`${BodyStyles}`,
  
  Footer: styled(SiteFooterLayout)`${FooterStyles}`,
};

export const Layout: React.FC<LayoutPropTypes> = (props) => {
  const { className, children } = props;

  const embedComponents = useEmbedComponents();

  const ref = React.useRef();

  const webConfig: WebConfig = {
    layouts: { pageLayouts, sliceLayouts, defaultPageLayout: pageLayouts.PageLayoutDefault },
    langStrings: LANG_STRINGS,
    theme: themeOverrides,
    header: {
      barHeight: 3,
    },
    menuBar: {
      isInverted: true,
    },
    entity: {
      dataProviders: [
        PostsProvider, MenusProvider,
      ]
    }
  }

  return (
    <WebProvider value={webConfig}>
    <EmbedComponentsProvider value={{
      ...embedComponents,
    }}>
      <My.Container
        id="#container"
        ref={ref}
        hideOverflow={false}
        className={className}
        includeTooltip
        modalColor={DARK_BLUE}
        tooltipProps={{
          backgroundColor: VERY_LIGHT_BROWN,
          globalEventOff: 'click',
        }}
      >
        <My.Body>
          {children}
        </My.Body>
        <My.Footer backgroundColor={VERY_LIGHT_BROWN} />
      </My.Container>
    </EmbedComponentsProvider>
    </WebProvider>
  );
  
  
};

Layout.defaultProps = LayoutDefaultProps;

