import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { BANNER_BLUE_BG } from '../../theme';
import { SliceLayoutColorStrip, SliceLayoutKnowledgeHub } from '../slice-layouts';
import { EntityTitleHeader } from '@shapeable/web';

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
};

export const PageLayoutKnowledgeHub: PageLayoutComponent = 
(props) => {
  const { className, entity, slices } = props;
  
  return (
    <My.Container className={className}>
      <EntityTitleHeader backgroundImage={BANNER_BLUE_BG} entity={entity} />
      <SliceLayoutColorStrip />
      {slices}
      <SliceLayoutKnowledgeHub entity={entity} />
    </My.Container>
  )
};